import { intervalToDuration } from "date-fns";
import { DurationFactory } from "../duration-factory";
import { DateSelectCursor } from "./date-select-cursor";

export class DefaultDateSelectCursor extends DateSelectCursor {

  createPrevStartDuration(): Duration {
    return this.defaultDuration();
  }
  createPrevEndDuration(): Duration {
    return this.defaultDuration();
  }
  createNextStartDuration(): Duration {
    return this.defaultDuration();
  }
  createNextEndDuration(): Duration {
    return this.defaultDuration();
  }

  private defaultDuration() {
    let duration = intervalToDuration({start: this.start, end: this.end});

    return new DurationFactory().createEmptyDurationWithDays((duration.days ?? 0) + 1);
  }



}
