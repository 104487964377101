import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRange } from '../../classes/daterange';
import { Ranges } from '../../classes/ranges';
import sub from 'date-fns/sub';
import { DateSelectCursorFactory } from '../../classes/date/date-select-cursor-factory';

@Component({
  selector: 'ganal-dateselect-picker',
  templateUrl: './dateselect-picker.component.html',
  styleUrls: ['./dateselect-picker.component.scss']
})
export class DateselectPickerComponent implements OnInit {
  @Input() currentRange!: DateRange;
  @Output() range = new EventEmitter()

  selectedRange!: any;

  lastMonth = sub(new Date(), { months: 1});
  today = new Date();

  constructor() {}

  ngOnInit(): void {
    let initialRange = new Ranges().ranges['LAST_7_DAYS'];
    this.selectedRange = [initialRange.from, initialRange.to];
  }

  selectRange(range: any) {
    if (range[0] === null || range[1] === null) {
      return;
    }

    this.selectedRange = range;
    this.range.emit(new DateRange(range[0], range[1]))
  }

  prevRange() {
    let cursor = new DateSelectCursorFactory().createFromDateRange(this.selectedRange[0], this.selectedRange[1]);
    let range = cursor.getPrevRange();

    this.selectRange([range.getFrom(), range.getTo()]);
  }

  nextRange() {
    let cursor = new DateSelectCursorFactory().createFromDateRange(this.selectedRange[0], this.selectedRange[1]);
    let range = cursor.getNextRange();

    this.selectRange([range.getFrom(), range.getTo()]);
  }

}
