import { IGraphFormatter } from "./igraph-formatter";

export class GraphCostFormatterBuilder implements IGraphFormatter {

  private value: any;

  constructor(value: any) {
    this.value = value;
  }

  public format(): string {
    let valueInK = this.value / 1000000000

    return '€ ' + valueInK.toFixed(1) + 'K';
  }

}



