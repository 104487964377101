<mave-modal #modal [open]="open" [title]="title">
    <div class="flex flex-col items-center py-4">
        <div class="flex justify-center items-center rounded-full bg-red-light w-14 h-14">
            <i class="material-symbols-outlined text-4xl text-red">delete</i>
        </div>
        <div class="text-xl font-bold text-red mt-4 break-words max-h-56 overflow-y-auto place-content-center">
          {{ message }}
        </div>
    </div>
    <div class="flex justify-between" footer>
        <mave-button label="Delete" variant="danger" (click)="deleteAction()" [disabled]="isDisabled"></mave-button>
        <mave-button label="Cancel" variant="secondary" (click)="closeModal()"></mave-button>
    </div>
</mave-modal>

