<div class="flex items-center h-6" (click)="stopPropagation($event)" (mouseleave)="setIsHovered(false)"  (mouseenter)="setIsHovered(true)">
    <div class="overflow-hidden">
      <ng-content *ngIf="!isEditing"></ng-content>

      <input
          (keyup.enter)="toggleEditingMode()"
          class="font-bold text-sm text-secondary outline-none border-b-2 border-gray"
          *ngIf="isEditing"
          type="text"
          [(ngModel)]="currentValue" />
    </div>
    <div (click)="toggleEditingMode()" [ngClass]="{'visible': isHovered, 'invisible': !isHovered}">
        <div class="material-symbols-outlined flex items-center justify-center text-sm bg-secondary-lighter text-white rounded-full h-6 w-6 ml-3 p-2 cursor-pointer hover:bg-primary active:bg-primary-dark">edit</div>
    </div>
</div>
