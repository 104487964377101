import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../mavejs/util/notification.service';
import { IRange, Ranges } from 'src/app/lib/classes/ranges';
import { DataSyncService, DataSyncType } from '../../../sync/services/data-sync.service';
import { IAccount } from '../../../account/interfaces/account';
import { AccountService } from '../../../account/services/account.service';

@Component({
  selector: 'ganal-summarize-form',
  templateUrl: './summarize-form.component.html',
  styleUrls: ['./summarize-form.component.scss']
})
export class SummarizeFormComponent implements OnInit {
  @Output() submitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isSubmitting: EventEmitter<boolean> = new EventEmitter<boolean>();

  summarizeForm!: UntypedFormGroup;
  ranges: IRange[] = Ranges.getItems();
  accounts!: IAccount[];

  types = [
    { value: DataSyncType.Campaign, label: 'Campaigns'},
    { value: DataSyncType.AdGroup, label: 'Ad Groups'},
    { value: DataSyncType.Ad, label: 'Ads'},
    { value: DataSyncType.Device, label: 'Devices'},
    { value: DataSyncType.Age, label: 'Ages'},
    { value: DataSyncType.Gender, label: 'Genders'},
    { value: DataSyncType.Parental, label: 'Parentals'},
    { value: DataSyncType.Income, label: 'Incomes'}
  ]

  constructor(private fb: UntypedFormBuilder, private accountService: AccountService, private dataSyncService: DataSyncService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.accountService.getAccounts("name").subscribe((accounts: IAccount[]) => {
      this.accounts = accounts;
    });

    this.summarizeForm = this.fb.group({
      account: ['', [Validators.required]],
      range: ['', [Validators.required]],
      type: ['', Validators.required]
    });
  }

  get account() {
    return this.summarizeForm?.get("account");
  }

  get range() {
    return this.summarizeForm?.get("range");
  }

  get type() {
    return this.summarizeForm?.get("type");
  }

  submitSummarizeForm() {
    this.summarizeForm.markAllAsTouched();

    if (!this.summarizeForm.valid) {
      return;
    }

    this.isSubmitting.emit(true);
    this.dataSyncService.summarizeMetrics(this.type?.value, this.account?.value, this.range?.value).subscribe(
        () => {
          this.submitted.emit(false)
          this.isSubmitting.emit(false);
        },
        () => {
          this.submitted.emit(true);
          this.isSubmitting.emit(false);
          this.notificationService.notify({
            title: "Cannot summarize",
            text: "Check if all options are correctly selected",
            type: "warning"
          })
        }
    );
  }

}
