<!--  DESKTOP  -->
<div class="hidden lg:block w-full">
  <div class="flex flex-row items-center">
    <div class="mr-2 dateselect">
      <ganal-dateselect-picker #picker [currentRange]="selectedRange" (range)="selectDateRange($event)"></ganal-dateselect-picker>
    </div>

    <div class="preset-ranges" ganalClickedOutside (clickOutside)="clickedOutside()">
      <!--      <div class="preset-ranges">-->
      <button class="bg-gray-light text-black-lighter hover:bg-gray font-bold py-2.5 px-3 rounded-md" (click)="showHideMenu()">{{selectedSummary | summary}}</button>
      <ul class="rounded rounded-lg bg-white shadow shadow-xs border border-gray" *ngIf="showMenu">
        <li *ngFor="let range of ranges | keyvalue: originalOrder" class="">
          <!-- hacky solution for range select -->
          <div [ngClass]="{'bg-gray-light': (range.key === selectedSummary)}" class="rounded-md">
            <mave-action
              [label]="range.key | summary"
              type="secondary"
              (click)="selectRange(range.key)">
            </mave-action>
          </div>
        </li>
        <li>
          <div [ngClass]="{'bg-gray-light': (selectedSummary === 'CUSTOM')}" class="rounded-md">
            <mave-action
              label="Custom"
              type="secondary">
            </mave-action>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<mave-card [header]="false" [footer]="false" class="lg:hidden">
  <!--  MOBILE  -->
  <div class="w-full lg:hidden">
    <div class="flex items-center justify-between">
      <div class="inline-block">
        <span class="bg-gray-light text-black-lighter font-bold py-2 px-3 rounded-md flex justify-between items-center">{{selectedSummary | summary}}</span>
      </div>
      <div class="inline-block">
        <span class="pi pi-calendar-plus text-3xl" (click)="showHideMenu()" *ngIf="!showMenu"></span>
        <span class="pi pi-calendar-minus text-3xl " (click)="showHideMenu()" *ngIf="showMenu"></span>
      </div>
    </div>

    <div class="flex flex-col justify-between items-center text-sm font-bold mt-5 dateselect" *ngIf="showMenu">
      <ganal-dateselect-picker #picker [currentRange]="selectedRange" (range)="selectDateRange($event)"></ganal-dateselect-picker>
      <div *ngFor="let range of ranges | keyvalue: originalOrder">
        <!-- hacky solution for range select -->
        <div [ngClass]="{'bg-gray-light': (range.key === selectedSummary)}" class="rounded-md" (click)="showHideMenu()">
          <mave-action
            [label]="range.key | summary"
            type="secondary"
            (click)="selectRange(range.key)">
          </mave-action>
        </div>
      </div>
      <div [ngClass]="{'bg-gray-light': (selectedSummary === 'CUSTOM')}" class="rounded-md">
        <mave-action
          label="Custom"
          type="secondary">
        </mave-action>
      </div>
    </div>
  </div>
</mave-card>
