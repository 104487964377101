import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[maveSelect]'
})
export class SelectDirective {
  @HostBinding('class') class = 'w-full outline-none p-3 h-12 text-secondary rounded rounded-lg bg-gray-light hover:bg-gray';

  constructor() { }

}
