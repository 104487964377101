<div class="mv-datatable-wrapper">
  <table *ngIf="!empty" [ngClass]="{'small-table': small, 'lg:table-fixed': fixed}" class="w-full">
      <thead *ngIf="headers" [ngClass]="{'border-b': ((headers.length > 0) ? true : false)}" class="border-gray">
          <tr>
              <td mave-datatable-header [ngClass]="{'hover:bg-gray-light': header.sortable, 'bg-gray-light': selectedHeader === header.value}" *ngFor="let header of headers">
                <div class="flex items-center group" [ngClass]="{'place-content-end': header.placeRight, 'cursor-pointer': header.sortable}" (click)="selectHeader(header.value, header.sortable)">
                  <i class="material-symbols-outlined text-sm font-bold mr-1" *ngIf="header.sortable && selectedHeader !== header.value">filter_list</i>
                  <i class="material-symbols-outlined text-sm font-bold mr-1" *ngIf="header.sortable && selectedHeader === header.value && selectedDirection === 'desc'">arrow_downward</i>
                  <i class="material-symbols-outlined text-sm font-bold mr-1" *ngIf="header.sortable && selectedHeader === header.value && selectedDirection === 'asc'">arrow_upward</i>
                  {{ header.label }}
                </div>
              </td>
          </tr>
      </thead>
      <tbody>
        <ng-content></ng-content>
      </tbody>
  </table>
  <mave-empty [small]="true" *ngIf="empty" title="No results found"></mave-empty>
</div>
