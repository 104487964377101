<div class="flex items-center px-2 bg-secondary-lightest rounded rounded-lg">
  <mave-action icon="chevron_left" size="small" type="secondary" (click)="prevRange()"></mave-action>
  <!--  DESKTOP  -->
  <div class="hidden lg:block">
    <p-calendar
      inputStyleClass="bg-secondary-lightest border-transparent hover:border-transparent cursor-pointer "
      [ngModel]="selectedRange"
      (ngModelChange)="selectRange($event)"
      [defaultDate]="lastMonth"
      [showTime]="false"
      [maxDate]="today"
      [hideOnDateTimeSelect]="true"
      rangeSeparator="-"
      selectionMode="range"
      dateFormat="dd/mm/yy"
      [firstDayOfWeek]="1"
      [numberOfMonths]="2"
      [selectOtherMonths]="true"
      >
    </p-calendar>
  </div>
  <!--  MOBILE  -->
  <div class="lg:hidden">
    <p-calendar
      inputStyleClass="bg-secondary-lightest border-transparent cursor-pointer text-center"
      [ngModel]="selectedRange"
      (ngModelChange)="selectRange($event)"
      [defaultDate]="lastMonth"
      [showTime]="false"
      [maxDate]="today"
      [hideOnDateTimeSelect]="true"
      rangeSeparator="-"
      selectionMode="range"
      dateFormat="dd/mm"
      [firstDayOfWeek]="1"
      [selectOtherMonths]="true"
      [numberOfMonths]="1"
      [touchUI]="true">
    </p-calendar>
  </div>
  <mave-action icon="chevron_right" size="small" type="secondary" (click)="nextRange()"></mave-action>
</div>
