import { GraphCostFormatterBuilder } from "./formatters/graph-cost-formatter-builder";
import { GraphFillFormatterBuilder } from "./formatters/graph-fill-formatter-builder";
import { GraphGeneralFormatter } from "./formatters/graph-general-formatter";
import { AdjustedGraphRoiFormatterBuilder } from "./formatters/adjusted-graph-roi-formatter-builder";
import { IGraphFormatter } from "./formatters/igraph-formatter";
import { AdjustedRoiGraphValueParser, GraphValueParser } from "./graph-value-parser";
import { GraphRoiFormatterBuilder } from "./formatters/graph-roi-formatter-builder";

export class GraphFormatterBuilder {

  public static get(value: number, rawValue: any, parser: GraphValueParser): IGraphFormatter {
    switch (parser.key()) {
      case "fill":
        return new GraphFillFormatterBuilder(rawValue);
      case "roi":
        return new GraphRoiFormatterBuilder(rawValue);
      case "adjustedRoi":
        return new AdjustedGraphRoiFormatterBuilder(value, <AdjustedRoiGraphValueParser> parser);
      case "cost":
        return new GraphCostFormatterBuilder(value);
      default:
        return new GraphGeneralFormatter(value);
    }
  }

}
