import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'summary'
})
export class SummaryPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return value.split('_')
      .map(item => item.toLowerCase())
      .map(item => item.substring(0, 1).toUpperCase() + item.substring(1, item.length))
      .join(' ');
  }

}
