import { IGraphFormatter } from "./igraph-formatter";

export class GraphRoiFormatterBuilder implements IGraphFormatter {

  private value: any;


  constructor(value: any) {
    this.value = value.roi;
  }

  format(): string {
    return Math.round(this.value) + '%';
  }

}
