import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EmptyComponent } from '../../base/empty/empty.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { DatatableHeaderComponent } from '../datatable-header/datatable-header.component';

@Component({
  selector: 'mave-datatable',
  imports: [EmptyComponent, NgIf, NgFor, NgClass, DatatableHeaderComponent],
  standalone: true,
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {
  @Input() headers!: Array<IHeader>
  @Input() empty: boolean = false;
  @Input() small: boolean = false;
  @Input() fixed: boolean = false;
  @Input() sortable: boolean = true;

  @Output() headerSelect: EventEmitter<ISortEvent> = new EventEmitter<ISortEvent>();

  selectedHeader: string = "name";
  selectedDirection: string = "asc";

  constructor() { }

  ngOnInit(): void {
  }

  swapDirection() {
    if (this.selectedDirection === "asc") {
      this.selectedDirection = "desc";
      return;
    }

    if (this.selectedDirection === "desc") {
      this.selectedDirection = "asc";
      return;
    }

    return;
  }

  selectHeader(header: string, isSortable: boolean) {
    if(isSortable){
      if (this.selectedHeader === header) {
        this.swapDirection();
      }else{
        this.selectedDirection = "desc";
      }

      if(header === '#'){
        this.selectedDirection = "asc";
      }

      this.selectedHeader = header;
      this.headerSelect.emit({
        header: this.selectedHeader,
        direction: this.selectedDirection
      });
    }
  }

}

export interface ISortEvent {
  header: string;
  direction: string;
}

export interface IHeader {
  value: string;
  label: string;
  placeRight: boolean;
  sortable: boolean;
}
