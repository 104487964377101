import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[maveDataTableRow]',
})
export class DatatableRowDirective {
  @HostBinding('class') class = 'odd:bg-whitish hover:bg-gray-light whitespace-nowrap'

  constructor() { }

}
