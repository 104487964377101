import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'mave-action',
  imports: [CommonModule],
  standalone: true,
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit {
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() description: string = '';
  @Input() type: string = 'button';
  @Input() active: boolean = false;
  @Input() disabled: boolean = false;

  @Output() press = new EventEmitter();

  typeClasses = []

  constructor() { }

  ngOnInit(): void {
    this.typeClasses = this.getTypeClasses(this.type);

    if (this.active) {
      this.setActiveWithTypeClasses(this.typeClasses, this.type);
    }
  }

  setActiveWithTypeClasses(typeClasses: Array<string>, type: string) {
    const types: any = {
      'button': ['bg-secondary-lighter', 'text-whitish'],
      'edit': ['bg-secondary-lighter', 'text-whitish'],
      'secondary': ['bg-gray-light'],
      'delete': ['bg-red-dark', 'text-white'],
      'retry': ['bg-green-dark']
    }

    for (let className of types[type] ?? []) {
      typeClasses.push(className);
    }
  }

  getTypeClasses(type: string) {
    const types: any = {
      'button': ['text-primary', 'hover:bg-primary-lightest', 'active:bg-secondary-lighter', 'active:text-whitish'],
      'edit': ['text-primary', 'hover:bg-primary-lightest', 'active:bg-secondary-lighter', 'active:text-whitish'],
      'secondary': ['text-black-lighter', 'hover:bg-gray-light', 'active:bg-gray-dark'],
      'delete': ['text-red-dark', 'hover:bg-red-light', 'active:bg-red-dark', 'active:text-white'],
      'retry': ['text-green-dark', 'hover:bg-green-light', 'active:bg-green-dark', 'active:text-white'],
    }

    return types[type] ?? []
  }

  pressButton(event: any) {
    if (this.disabled) {
      return;
    }

    this.press.emit(event);
  }

}
