export interface GraphValueParser {

  parse(value: any): any;
  key(): string;

}


export class NoopGraphValueParser {

  parse(value: any) {
    return value;
  }

  key() {
    return 'none';
  }

}

export class FillGraphValueParser implements GraphValueParser {

  parse(value: any) {
    return value.percentage;
  }

  key(): string {
    return "fill";
  }

}


export class AdjustedRoiGraphValueParser implements GraphValueParser{

  maxCost: number;

  constructor(maxCost: number) {
    this.maxCost = maxCost;
  }

  parse(value: any) {
    return (value.roi * this.maxCost) / 100;
  }

  reverse(value: any) {
    return (value / this.maxCost) * 100;
  }

  key(): string {
    return "adjustedRoi";
  }

}

export class RoiGraphValueParser implements GraphValueParser {

  parse(value: any) {
    return this.adapt(value.roi);
  }

  adapt(value: any) {
    if (value < 20) {
      return 20;
    }

    if (value > 100) {
      return 100;
    }

    return value;
  }

  key() {
    return "roi";
  }

}

export class CostGraphValueParser implements GraphValueParser {

  parse(value: any) {
    return value.cost;
  }

  key() {
    return "cost";
  }

}

export class ProfitGraphValueParser implements GraphValueParser {

  parse(value: any) {
    return value / 100;
  }

  key() {
    return "profit";
  }

}
