import { Parser } from "@angular/compiler";
import { AdjustedRoiGraphValueParser, GraphValueParser } from "../graph-value-parser";
import { IGraphFormatter } from "./igraph-formatter";

export class AdjustedGraphRoiFormatterBuilder implements IGraphFormatter {

  private value!: any;
  private parser!: AdjustedRoiGraphValueParser;

  constructor(value: any, parser: AdjustedRoiGraphValueParser) {
    this.value = value;
    this.parser = parser;
  }

  public format(): string {
    return Math.round(this.parser.reverse(this.value)) + '%';
  }

}
