import { CommonModule, NgClass, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'mave-button',
  imports: [NgClass, NgIf],
  standalone: true,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() label!: string;
  @Input() variant: string = 'primary';
  @Input() icon: string = '';
  @Input() spin: boolean = false;
  @Input() size: string = '';
  @Input() type: string = 'button'
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;

  @Output() press = new EventEmitter();

  selectedClasses: Array<string>;

  constructor() {
    this.selectedClasses = []
  }

  ngOnInit(): void {
    this.selectedClasses = this.getSelectedClasses();

    if (this.size === 'small') {
      this.selectedClasses.push("py-2")
    } else {
      this.selectedClasses.push("py-3")
    }
  }


  getSelectedClasses() {
    const variants: any = {
      'primary': ['mv-button-primary'],
      'danger': ['bg-red', 'text-white', 'hover:bg-red-lighter', 'active:bg-red-dark'],
      'warning': ['bg-yellow-light', 'text-yellow-dark', 'hover:bg-yellow', 'hover:text-yellow-dark'],
      'secondary': ['mv-button-secondary'],
      'retry': ['bg-green', 'text-green-dark', 'hover:bg-green-light', 'active:bg-green-dark', 'active:text-green']
    };

    return variants[this.variant] !== undefined ? variants[this.variant] : ['hover:bg-gray-light']
  }

  pressButton(event: any) {
    if (this.disabled) {
      return;
    }

    this.press.emit(event);
  }

}
