import { format } from 'date-fns'

export class DateRange {
    from = new Date();
    to = new Date();

    constructor(from: Date, to: Date) {
      this.from = from;
      this.to = to;
    }

    getFrom(): Date {
      return this.from;
    }

    getTo(): Date {
      return this.to;
    }

    setFrom(date: Date) {
      this.from = date;
    }

    setTo(date: Date) {
      this.to = date;
    }

    getFromFormatted(): string {
      return format(this.from, 'yyyy-MM-dd');
    }

    getToFormatted(): string {
      return format(this.to, 'yyyy-MM-dd');
    }

    toString(): string {
      return this.getFromFormatted() + " - " + this.getToFormatted();
    }
}
