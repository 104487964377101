import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { IAccount } from 'src/app/account/interfaces/account';

@Injectable({
  providedIn: 'root'
})
export class DataSyncService {

  constructor(private backend: BackendService) { }

  syncData(type: string, account: IAccount) {
    return this.backend.sendPost("/api/v1/" + type + "/sync/" + account.id, {})
  }

  syncMetrics(type: string, account: IAccount, date: Date) {
    return this.backend.sendPost("/api/v1/" + type + "/sync/" + account.id + "/metrics/" + + date.getFullYear() + '/' + (date.getMonth() + 1) + "/" + date.getDate(), {});
  }

  summarizeMetrics(type: string, accountId: number, range: string) {
    return this.backend.sendPost("/api/v1/" + type + "/sync/" + accountId + "/summary/" + range, {});
  }

  syncAll(date: Date) {
    return this.backend.sendPost("/api/v1/sync/all/" + date.getFullYear() + '/' + (date.getMonth() + 1) + "/" + date.getDate(), {})
  }

  syncAllInRange(fromDate: Date, toDate: Date) {
    return this.backend.sendPost("/api/v1/sync/all/" + fromDate.getFullYear() + '/' + (fromDate.getMonth() + 1) + "/" + fromDate.getDate() + "/to/" + toDate.getFullYear() + '/' + (toDate.getMonth() + 1) + "/" + toDate.getDate(), {})
  }

  summarizeAll() {
    return this.backend.sendPost("/api/v1/sync/all/summarize", {})
  }

}

export enum DataSyncType {
  Account = 'account',
  Campaign = 'campaign',
  AdGroup = 'ad_group',
  Ad = 'ad',
  Device = 'device',
  Age = 'age',
  Gender = 'gender',
  Parental = 'parental',
  Income = 'income',
  Video = 'video',
  Product = 'product'
}
