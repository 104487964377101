import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mave-title',
  standalone: true,
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {
  @Input() text: string = 'Title'

  constructor() { }

  ngOnInit(): void {
  }

}
