<div class="p-6 z-50 transition cursor-pointer transform hover:scale-105 mx-auto text-white rounded-md mb-3" [ngClass]="typeClasses">
    <div class="flex">
        <div class="mr-6">
            <i class="material-symbols-outlined text-4xl">{{ typeIcon }}</i>
        </div>
        <div>
            <div class="font-bold">{{ title }}</div>
            <div class="mt-2 text-sm">{{ text }}</div>
        </div>
    </div>
</div>
