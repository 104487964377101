<form [formGroup]="summarizeForm" (submit)="submitSummarizeForm()">
  <div class="mb-2">
    <mave-label for="account">Account</mave-label>
    <select class="mt-1" formControlName="account" maveSelect>
      <option [value]="''">Select Account</option>
      <option *ngFor="let account of accounts" [value]="account.id">{{ account.name }}</option>
    </select>
    <div>
      <mave-validation name="Account" [control]="account"></mave-validation>
    </div>
  </div>

  <div class="mb-2">
    <mave-label for="range">Range</mave-label>
    <select class="mt-1" formControlName="range" maveSelect>
      <option [value]="''">Select Range</option>
      <option *ngFor="let range of ranges" [value]="range.name">{{ range.name | summary }}</option>
    </select>
    <div>
      <mave-validation name="Range" [control]="range"></mave-validation>
    </div>
  </div>

  <div class="mb-2">
    <mave-label for="type">Type</mave-label>
    <select class="mt-1" formControlName="type" maveSelect>
      <option [value]="''">Select Type</option>
      <option *ngFor="let type of types" [value]="type.value">{{ type.label }}</option>
    </select>
    <div>
      <mave-validation name="Type" [control]="type"></mave-validation>
    </div>
  </div>
</form>
