import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

export interface Notification {
  id?: number;
  title: string;
  text: string;
  type?: string;
}

type NotificationCallback = (notification: Notification) => void;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private handler = new Subject<Notification>()

  notify(notification: Notification) {
    this.handler.next(notification);
  }

  subscribe(notification: NotificationCallback): Subscription {
    return this.handler
      .pipe()
      .subscribe(notification);
  }

}


