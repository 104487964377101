import { CommonModule, NgForOf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mave-notification',
  standalone: true,
  imports: [CommonModule, NgForOf],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  @Input() title: string = 'A notification has been fired';
  @Input() text: string = '';
  @Input() type?: string = 'info';

  typeClasses = [];
  typeClassesMapping: any = {
    "info": ['bg-black', 'hover:bg-black-lighter'],
    "warning": ['bg-yellow', 'hover:bg-yellow-lighter', 'text-yellow-dark'],
    "error": ['bg-red', 'hover:bg-red-lighter'],
    "unauthorized": ['bg-red', 'hover:bg-red-lighter']
  }
  typeIconMapping: any = {
    "info": "info",
    "warning": "warning",
    "error": "warning",
    "unauthorized": "lock"
  }
  typeIcon = "info";

  constructor() { }

  ngOnInit(): void {
    if (this.type === undefined) {
      this.type = "info"
    }

    this.typeClasses = this.typeClassesMapping[this.type];
    this.typeIcon = this.typeIconMapping[this.type];
  }

}
