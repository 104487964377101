import { add, getDaysInMonth, sub } from "date-fns";
import { DurationFactory } from "../duration-factory";
import { DateSelectCursor } from "./date-select-cursor";

export class MonthlyDateSelectCursor extends DateSelectCursor {

  createNextStartDuration(): Duration {
    return new DurationFactory().createEmptyDurationWithMonths(1);
  }

  createNextEndDuration(): Duration {
    return new DurationFactory().createEmptyDurationWithDays(getDaysInMonth(add(this.end, { months: 1})));
  }

  createPrevStartDuration(): Duration {
    return new DurationFactory().createEmptyDurationWithMonths(1);
  }

  createPrevEndDuration(): Duration {
    return new DurationFactory().createEmptyDurationWithDays(getDaysInMonth(this.end));
  }

}
