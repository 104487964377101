import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mave-card',
  imports: [CommonModule],
  standalone: true,
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() header: boolean = true;
  @Input() footer: boolean = true;
  @Input() border: boolean = true;
  @Input() variant: string = 'light';
  @Input() bgColor: string = '';

  headerClasses: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
    this.headerClasses = this.getHeaderClasses()
  }

  getHeaderClasses(): Array<string> {
    const headers: any = {
      'light': ['bg-white', 'text-black', 'border-b', 'border-gray'],
      'dark': ['bg-black', 'text-black']
    }

    return headers[this.variant] ?? []
  }

}
