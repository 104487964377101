import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'td[mave-datatable-column-mono]',
  standalone: true,
  templateUrl: './datatable-column-mono.component.html',
  styleUrls: ['./datatable-column-mono.component.css']
})
export class DatatableColumnMonoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
