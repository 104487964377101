<div class="bg-white w-full top-0 z-40 whitespace-nowrap overflow-x-scroll overflow-y-visible lg:overflow-visible">
  <!--  DESKTOP  -->
  <div class="hidden lg:block lg:flex items-center lg:justify-between px-2 border-b border-gray lg:px-10 overflow-x-scroll overflow-y-visible lg:overflow-visible">
    <div class="flex items-center">
      <a class="p-3 hover:bg-gray-lightest active:bg-gray-light transition rounded rounded-lg" routerLink="/">
        <div class="flex">
          <div class="flex items-center">
            <img src="assets/logo.png" class="h-12 -mt-2 -mb-2 cursor-pointer" alt="Mave Logo" />
          </div>
          <div class="flex align-center flex-col ml-3">
            <h3 class="m-0 font-bold text-black">Mave</h3>
            <h2 class="m-0 text-sm -mt-1 font-bold text-primary-light">Google Analytics</h2>
          </div>
        </div>
      </a>
      <ganal-refresh-metrics></ganal-refresh-metrics>
    </div>
    <mave-title text="{{pageTitle}}" class="font-mono"></mave-title>
    <div class="flex items-center py-5 text-secondary">
      <ganal-navbar-item link="/reports" label="Reports"></ganal-navbar-item>
      <ganal-navbar-item link="/daily-reports" label="Daily Reports"></ganal-navbar-item>
      <ganal-navbar-item link="/campaign/recent" label="Recent campaigns"></ganal-navbar-item>

      <mave-notify-badge [showGreenIndicator]="showGreenIndicator" [showOrangeIndicator]="showOrangeIndicator" [showRedIndicator]="showRedIndicator">
        <ganal-navbar-item link="/status" label="Statusses {{totalCountString}}"></ganal-navbar-item>
      </mave-notify-badge>

      <button pButton class="p-button-text" icon="pi pi-user" *ngIf="user" [label]="user.name" (click)="userMenu.toggle($event)"></button>

      <ganal-notifications></ganal-notifications>

      <p-menu #userMenu [popup]="true" [model]="items" appendTo="body"></p-menu>

      <div>
        <span class="pi pi-bars text-2xl py-3 block text-sm px-5 py-2 rounded-lg hover:text-primary-light hover:bg-gray-light cursor-pointer" (click)="desktopMenu.toggle($event)"></span>
        <p-menu #desktopMenu [popup]="true" [model]="desktopMenuItems" appendTo="body"></p-menu>
      </div>
    </div>
  </div>

  <!--  MOBILE  -->
  <div class="lg:hidden" id="mobileMenu">
    <div class="flex items-center justify-between w-full">
      <a class="w-10 mt-3 ml-4 lg:hidden" routerLink="/">
        <img src="favicon.ico" alt="Mave Logo" />
      </a>
      <ganal-refresh-metrics class="items-center"></ganal-refresh-metrics>
      <div>
        <span class="pi pi-bars text-3xl mt-3 mr-5" (click)="showHideMenu()" *ngIf="!showMenu"></span>
        <span class="pi pi-times text-3xl mt-3 mr-5" (click)="showHideMenu()" *ngIf="showMenu"></span>
      </div>
    </div>

    <div class="mt-2 px-5" *ngIf="showMenu">
      <ganal-navbar-item link="/reports" label="Reports" (click)="showHideMenu()"></ganal-navbar-item>
      <ganal-navbar-item link="/daily-reports" label="Daily Reports" (click)="showHideMenu()"></ganal-navbar-item>
      <ganal-navbar-item link="/campaign/recent" label="Recent campaigns" (click)="showHideMenu()"></ganal-navbar-item>
      <ganal-navbar-item link="/sync" label="Sync log" (click)="showHideMenu()"></ganal-navbar-item>
      <ganal-navbar-item link="/account" label="Accounts" (click)="showHideMenu()"></ganal-navbar-item>
      <ganal-navbar-item link="/template" label="Templates" (click)="showHideMenu()"></ganal-navbar-item>

      <mave-notify-badge [showGreenIndicator]="showGreenIndicator" [showOrangeIndicator]="showOrangeIndicator" [showRedIndicator]="showRedIndicator">
        <ganal-navbar-item link="/status" label="Statusses {{totalCountString}}" (click)="showHideMenu()"></ganal-navbar-item>
      </mave-notify-badge>

      <button pButton class="p-button-text" icon="pi pi-user" *ngIf="user" [label]="user.name" (click)="userMenu.toggle($event)"></button>

      <ganal-notifications></ganal-notifications>
      <p-menu #userMenu [popup]="true" [model]="items" (click)="showHideMenu()"></p-menu>
    </div>

  </div>
</div>

