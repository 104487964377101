import { add, sub, subMonths } from "date-fns";
import { DateRange } from "../daterange";

export abstract class DateSelectCursor {

  start: any;
  end: any;

  constructor(start: any, end: any) {
    this.start = start;
    this.end = end;
  }

  abstract createPrevStartDuration(): Duration;
  abstract createPrevEndDuration(): Duration;
  abstract createNextStartDuration(): Duration;
  abstract createNextEndDuration(): Duration;

  getPrevRange(): DateRange {
    let start = sub(this.start, this.createPrevStartDuration());
    let end = sub(this.end, this.createPrevEndDuration());

    return new DateRange(start, end)
  }

  getNextRange(): DateRange {
    let start = add(this.start, this.createNextStartDuration());
    let end = add(this.end, this.createNextEndDuration());

    return new DateRange(start, end)
  }


}
