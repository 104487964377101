import { IGraphFormatter } from "./igraph-formatter";

export class GraphFillFormatterBuilder implements IGraphFormatter {

  private value: any;

  constructor(value: any) {
    this.value = value;
  }

  public format(): string {
    return '\n€' + this.value.cost + '\n' + this.value.budget;
  }

}
