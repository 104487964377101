import { DurationFactory } from "../duration-factory";
import { DateSelectCursor } from "./date-select-cursor";

export class ThisWeekDateSelectCursor extends DateSelectCursor {

  createPrevStartDuration(): Duration {
    return this.createWeeklyDuration();
  }

  createPrevEndDuration(): Duration {
    return this.createWeeklyDuration();
  }

  createNextStartDuration(): Duration {
    return this.createWeeklyDuration();
  }

  createNextEndDuration(): Duration {
    return this.createWeeklyDuration();
  }

  private createWeeklyDuration(): Duration {
    return new DurationFactory().createEmptyDurationWithDays(7);
  }

}
