<div
  *ngIf="!description"
  class="py-2 px-3 rounded-md flex justify-between items-center cursor-pointer"
  [ngClass]="typeClasses"
  (click)="pressButton($event)">
    <i class="material-symbols-outlined text-icon" *ngIf="icon">{{ icon }}</i>
    <div class="text-center ml-1 font-bold" *ngIf="label">{{ label }}</div>
</div>

<div
  *ngIf="description"
  class="py-2 px-3 rounded-md cursor-pointer"
  [ngClass]="typeClasses"
  (click)="pressButton($event)">
    <i class="material-symbols-outlined text-icon" *ngIf="icon">{{ icon }}</i>
    <div class="text-center ml-1 font-bold" *ngIf="label">{{ label }}</div>
    <div class="text-center ml-1 font-bold" *ngIf="description">{{ description }}</div>
</div>
