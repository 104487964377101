import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { subWeeks, startOfToday, startOfYesterday } from 'date-fns';
import { RefreshModalComponent } from './lib/components/refresh-modal/refresh-modal.component';
import { IAccountMetricsResult } from './account/interfaces/account-metrics-result';
import { AccountService } from './account/services/account.service';
import { DateRange } from './lib/classes/daterange';
import { IStatistics } from './lib/interfaces/statistics';
import { GraphOptions } from './campaign/classes/graph/graph-options';
import { GraphBuilder } from './campaign/classes/graph/graph-builder';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { FixedColorGraphPainter } from './campaign/classes/graph/graph-painter';
import { AdjustedRoiGraphValueParser, CostGraphValueParser } from './campaign/classes/graph/graph-value-parser';
import { ISortEvent } from './mavejs/data/datatable/datatable.component';
import { PageComponent } from './lib/components/page/page.component';
import { HttpGetComponent } from './mavejs/http/http-get/http-get.component';

@Component({
  selector: 'ganal-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends PageComponent implements OnInit {
  @ViewChild('accountRequest') accountRequest!: HttpGetComponent;
  @ViewChild('refreshModal') refreshModal!: RefreshModalComponent;

  accountService: AccountService
  accountHeaders = [
    { value: '', label: '', placeRight: false, sortable: false },
    { value: 'name', label: 'Name', placeRight: false, sortable: false },
    { value: 'cost', label: 'Cost', placeRight: true, sortable: false },
    { value: 'profit', label: 'Profit', placeRight: true, sortable: false },
    { value: 'roi', label: 'ROI', placeRight: true, sortable: false },
    { value: 'conversions', label: 'Conv', placeRight: true, sortable: false },
    { value: 'costConversions', label: 'Cost/Conv', placeRight: true, sortable: false },
    { value: 'revenue', label: 'Revenue', placeRight: true, sortable: false },
    { value: 'usCPM', label: 'US CPM', placeRight: true, sortable: false },
    { value: 'lastStatusChange', label: 'Last Status', placeRight: true, sortable: false }
  ]

  accountHeadersMobile = [
    { value: '', label: '', placeRight: false, sortable: false },
    { value: 'name', label: 'Name', placeRight: false, sortable: false },
    { value: 'cost', label: 'Cost', placeRight: true, sortable: false },
    { value: 'profit', label: 'Profit', placeRight: true, sortable: false },
    { value: 'roi', label: 'ROI', placeRight: true, sortable: false },
    { value: 'conversions', label: 'Conv', placeRight: true, sortable: false },
    { value: 'costConversions', label: 'Cost/Conv', placeRight: true, sortable: false },
    { value: 'revenue', label: 'Revenue', placeRight: true, sortable: false },
    { value: 'usCPM', label: 'US CPM', placeRight: true, sortable: false }
  ]

  page: number;
  range: DateRange = new DateRange(subWeeks(startOfToday(), 1), startOfYesterday())
  statistics!: IStatistics;
  accountMetrics!: Array<IAccountMetricsResult>;
  isLoadingStatistics = true;
  isLoadingAccounts = true;
  isLoadingGraph = true;
  sortBy: string = "cost";
  direction: string = "desc";
  currentYear: string = new Date().getFullYear().toString();

  scriptUrl: string = 'https://tech2020.co/r?l=GanalScript';

  graphData: any;
  graphOptions: any;


  constructor(
    accountService: AccountService,
    protected title: Title
  ) {
    super(title);

    this.accountService = accountService;
    this.page = 0;
    this.title.setTitle("Mave Google Analytics: Home");
  }

  ngOnInit(): void {
    this.getAccountMetrics();
  }

  getAccountMetrics() {
    this.isLoadingAccounts = true;

    this.accountService.getAccountMetrics(this.range, this.page, this.sortBy, this.direction).subscribe((accountMetrics: Array<IAccountMetricsResult>) => {
      this.accountMetrics = accountMetrics;
      this.isLoadingAccounts = false;
    });

    this.isLoadingStatistics = true;
    this.accountService.getAccountStatistics(this.range).subscribe((statistics: IStatistics) => {
      this.statistics = statistics;
      this.isLoadingStatistics = false;
    })

    this.isLoadingGraph = true;
    this.accountService.getAccountGraph(this.range).subscribe((graphData: any) => {
      let moreThan7days = graphData.length > 7;

      let costArray = graphData.map((item: any) => (item.y['cost']));
      let maxCost = Math.max(...costArray);

      this.graphData = new GraphBuilder()
        .push(graphData, new FixedColorGraphPainter('gray'), new AdjustedRoiGraphValueParser(maxCost))
        .push(graphData, new FixedColorGraphPainter('#CE0F0F'), new CostGraphValueParser())
        .build(GraphBuilder.labelsFromGraphData(graphData));

      this.graphOptions = GraphOptions.getAccountGraphOptions(!moreThan7days);
      Chart.register(ChartDataLabels);
      this.isLoadingGraph = false;
    })
  }

  dateRangeChanged(range: DateRange) {
    this.range = range;
    this.getAccountMetrics()
  }

  sort(event: ISortEvent) {
    this.sortBy = event.header;
    this.direction = event.direction;
    this.getAccountMetrics();
  }

  openRefreshAccountModal(accountId: number, accountName: string) {
    this.refreshModal.setAccount(accountId, accountName);
    this.refreshModal.openModal();
  }

  highlightConversion(mvolger: number, googleconv: number, type: string): String {
    //Conversion in het rood maken als het ene nul is en het andere groter dan twee.

    // Als MVolger < Google conv * 0.9
    // Als MVolger of Google conv ≥ 10
    // En als MVolger/Google conv ≥ 2 of Google/MVolger conv ≥ 2
    // Dan kleinste in het rood zetten

    if (type === 'mvolger') {
      if (mvolger < (googleconv * 0.9)) {
        return 'text-red';
      }

      if (mvolger === 0 && googleconv > 2) {
        return 'text-red';
      }

      if ((googleconv >= 10) && googleconv > mvolger) {
        if (googleconv / mvolger >= 2) {
          return 'text-red';
        }
      }
    }

    if (type === 'google') {
      if (googleconv === 0 && mvolger > 2) {
        return 'text-red';
      }

      if ((mvolger >= 10) && mvolger > googleconv) {
        if (mvolger / googleconv >= 2) {
          return 'text-red';
        }
      }
    }

    return '';
  }

  get showGraph() {
    if (this.graphData === undefined) {
      return false;
    }

    return this.graphData.datasets[0].data.length > 1;
  }

}



