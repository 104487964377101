import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mave-tooltip',
  imports: [NgClass],
  standalone: true,
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnInit {
  @Input() text: string = 'Tooltip text here';
  @Input() forceHide: boolean = false;
  @Input() direction: string = '';

  isOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if(this.direction === ''){
      this.direction = 'right';
    }
  }

}
