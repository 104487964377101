import { Duration } from "date-fns";

export class DurationFactory {


  public createEmptyDuration() : Duration {
    return {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  public createEmptyDurationWithDays(days: number): Duration {
    return {
      years: 0,
      months: 0,
      weeks: 0,
      days: days,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  public createEmptyDurationWithMonths(months: number): Duration {
    return {
      years: undefined,
      months: months,
      weeks: undefined,
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
    }
  }

}
