import {Component, OnInit, Output, EventEmitter, ViewChild, Input} from '@angular/core';
import { intervalToDuration } from 'date-fns';
import { DateRange } from '../../classes/daterange';
import { Ranges } from '../../classes/ranges';
import { IDateRangeMapping, ISummaryMapping } from '../../interfaces/date-range-mapping';

import { DateselectPickerComponent } from '../dateselect-picker/dateselect-picker.component';

@Component({
  selector: 'ganal-dateselect',
  templateUrl: './dateselect.component.html',
  styleUrls: ['./dateselect.component.scss']
})
export class DateselectComponent implements OnInit {
  @ViewChild('picker') dateRangePicker!: DateselectPickerComponent
  @Output() dateChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();
  @Input() customRangeSelection?: string[];

  ranges: IDateRangeMapping = new Ranges().ranges;
  customRange?: IDateRangeMapping;
  summaries: ISummaryMapping = new Ranges().rangesReversed;
  selectedRange: DateRange = this.ranges['LAST_7_DAYS'];
  selectedSummary = 'LAST_7_DAYS';
  showMenu: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if(this.customRangeSelection){
      this.filterRanges(this.customRangeSelection);
    }
  }

  originalOrder(): number {
    return 0;
  }

  /**
   * select a predetermined rage
   * @param summary: selected range
   */
  selectRange(summary: string) {
    if (this.ranges[summary] === undefined) {
      this.selectedSummary = 'CUSTOM'
    }

    this.selectedRange = this.ranges[summary];
    this.selectedSummary = summary;
    this.dateRangePicker.selectRange([this.selectedRange.getFrom(), this.selectedRange.getTo()]);

    this.dateChange.emit(this.selectedRange);

    this.showHideMenu();
  }

  /**
   * Select a date from the calendar -> ganal-dateselect-picker
   * @param range: selected range
   */
  selectDateRange(range: DateRange) {
    if (range.toString() === this.selectedRange.toString()) {
      return;
    }

    let currentSummary = this.summaries[range.toString()];
    if (this.ranges[currentSummary] === undefined) {
      this.selectedSummary = 'CUSTOM'
    }else{
      this.selectedSummary = currentSummary;
    }

    this.selectedRange = range;
    this.dateChange.emit(this.selectedRange);
  }

  filterRanges(rangeSelection: string[]){
    this.customRange = rangeSelection.reduce((obj, key) => {
      // @ts-ignore
      obj[key] = this.ranges[key];
      return obj
    }, {});

    this.ranges = this.customRange;
  }

  showHideMenu(){
    this.showMenu = !this.showMenu;
  }

  clickedOutside(){
    //Check if you're on mobile or desktop.
    if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.showMenu = false;
    }
  }

}
