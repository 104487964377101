import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mave-notify-badge',
  imports: [NgClass, NgIf],
  standalone: true,
  templateUrl: './notify-badge.component.html',
  styleUrls: ['./notify-badge.component.css']
})
export class NotifyBadgeComponent implements OnInit {
  @Input() text!: string;
  @Input() showCount!: boolean;
  @Input() position!: string;
  @Input() small: boolean = false;
  @Input() showGreenIndicator!: boolean;
  @Input() showOrangeIndicator!: boolean;
  @Input() showRedIndicator!: boolean;

  constructor() { }

  ngOnInit(): void {

  }

}
