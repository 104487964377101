<mave-modal #modal title="Summarize account">

  <ganal-summarize-form #summarizeForm (submitted)="closeModal()" (isSubmitting)="isSubmitting = $event"></ganal-summarize-form>

  <div footer>
    <div class="flex justify-between">
      <mave-button label="Summarize" [loading]="isSubmitting" (press)="summarizeForm.submitSummarizeForm()"></mave-button>
      <mave-button label="Cancel" variant="secondary" (press)="closeModal()"></mave-button>
    </div>
  </div>

</mave-modal>
