import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'td[mave-datatable-header]',
  standalone: true,
  templateUrl: './datatable-header.component.html',
  styleUrls: ['./datatable-header.component.scss']
})
export class DatatableHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
