import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'mave-editable',
  imports: [NgClass, NgIf, FormsModule],
  standalone: true,
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.css']
})
export class EditableComponent implements OnInit {
  @Input() value!: string;
  @Input() link: string = '';
  @Output() changeValue: EventEmitter<string> = new EventEmitter();

  isEditing!: boolean;
  currentValue = this.value;
  isHovered!: boolean;

  constructor() { }

  ngOnInit(): void {
    this.isEditing = false
    this.currentValue = this.value;
    this.isHovered = false;
  }

  toggleEditingMode() {
    this.isEditing = !this.isEditing

    if (this.isEditing === false) {
      this.changeValue.emit(this.currentValue);
    }
  }

  setIsHovered(status: boolean) {
    this.isHovered = status;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

}
