import { isFirstDayOfMonth, isLastDayOfMonth } from "date-fns";
import { DateRange } from "../daterange";
import { Ranges } from "../ranges";
import { DateSelectCursor } from "./date-select-cursor";
import { DefaultDateSelectCursor } from "./default-date-select-cursor";
import { MonthlyDateSelectCursor } from "./monthly-date-select-cursor";
import { ThisWeekDateSelectCursor } from "./this-week-date-select-cursor";

export class DateSelectCursorFactory {

  private isFullMonth(start: any, end: any) {
    return (isFirstDayOfMonth(start) && isLastDayOfMonth(end));
  }

  public createFromDateRange(start: any, end: any): DateSelectCursor {
    let dateRangeString = new DateRange(start, end).toString();
    let ranges = new Ranges().rangesReversed;

    if (ranges[dateRangeString] === 'THIS_WEEK') {
      return new ThisWeekDateSelectCursor(start, end);
    }

    if (ranges[dateRangeString] === 'LAST_MONTH' || this.isFullMonth(start, end)) {
      return new MonthlyDateSelectCursor(start, end);
    }

    return new DefaultDateSelectCursor(start, end);
  }

}
